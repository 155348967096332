/*Theme color*/
$primary: #0094ff;
$success: #00d058;
$warning: #f88a0a;
$danger: #ff0000;
$white: #ffffff;
$black: #000000;

// Base
// -------------------------------
$color-dark: $black;
$body-bg: $white;
$body-color: $black !default;

$background-color: #000000;
$base-color: #ffffff;
$line-height-base: 1.5 !default;
$border-radius: 8px;
$border-radius-lg: 14px;

// Font
$font-size-base: 12px !default;
$font-family-base: $font-family;
$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-medium: 500 !default;
$font-weight-semibold: 600 !default;
$font-weight-bold: 700 !default;
$font-weight-extrabold: 800 !default;
$font-weight-base: $font-weight-normal !default;

// Buttons
$btn-padding-y: 0.75rem !default;
$btn-padding-x: 1.5rem !default;
$btn-font-size: rem(10) !default;
$btn-line-height: 12.19px !default;
$btn-border-radius: $border-radius-lg !default;
$btn-font-weight: $font-weight-semibold !default;

// Forms
// -------------------------------
$input-bg: $black;
$input-height: 35px;
$input-border-width: 0 0 1px 0;
$input-border-color: #505051;
$input-bg-focus: $black;
$input-radius: 14px;
$input-font-size: rem(14);
$input-line-height: 17px;
$input-text-color: $base-color;
$input-color-placeholder: $base-color;
$form-group-bottom: 20px;
