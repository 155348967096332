.list-group {
    .list-header {
        margin-bottom: 16px;
        @extend .flexbox;
        .btn {
            text-transform: uppercase;
            line-height: 12px;
        }
    }

    &-item {
        &:not(:last-child) {
            margin-bottom: 16px;
        }
        h3 {
            font-weight: $font-weight-medium;
            font-size: rem(14);
            line-height: 17px;
            margin-bottom: 5px;
            @extend .text-ellipsis;
        }
        p {
            @extend .text-ellipsis;
            line-height: 15px;
            &:not(.nft-fenix) {
                mix-blend-mode: normal;
                opacity: 0.9;
            }
        }
        .list-img {
            margin-bottom: 8px;
            width: 150px;
            height: 150px;
            border-radius: $border-radius-lg;
            overflow: hidden;
            @extend .position-relative;
            img {
                @extend .img-cover;
            }
            .icon-lists {
                position: absolute;
                right: 16px;
                bottom: 16px;
                display: flex;
                flex-direction: column;
                .btn {
                    padding: 0;
                    background: linear-gradient(
                        135deg,
                        var(--btn-gradient-startcolor) 0%,
                        var(--btn-gradient-endcolor) 100%
                    );
                    border-radius: 50%;
                    width: 32px;
                    height: 32px;
                    & + .btn {
                        margin-top: 6px;
                    }
                }
            }
        }
        .list-video {
            @extend .position-relative;
        }
    }
    &-horizontal {
        // display: flex;
        // flex-direction: column;
        // overflow-x: auto;
        // overflow-y: hidden;
        .list-scroll {
            margin-left: -20px;
            margin-right: -20px;
            width: calc(100% + 40px);
            display: flex;
            flex-wrap: nowrap;
            overflow-x: auto;
            overflow-y: hidden;
            -webkit-overflow-scrolling: touch;
            -ms-overflow-style: -ms-autohiding-scrollbar;
        }
        .list-group-item {
            width: 150px;
            margin-bottom: 0 !important;
            margin-right: 15px;
            &:first-child {
                margin-left: 20px;
            }
        }
        .list-scroll-nft {
            .list-img {
                height: 100px;
            }
            .nft-fenix {
                margin-top: 5px;
                @extend .flexbox;
                // display: block;
                @extend .position-relative;
                span {
                    @extend .text-ellipsis;
                    padding-right: 4px;
                }
                .btn {
                    flex-shrink: 0;
                }
            }
        }
        .list-scroll-products {
            .list-group-item {
                width: 160px;
            }
            .list-img {
                width: 160px;
                height: 180px;
            }
        }
    }
}

.list-group-social {
    .list-group-item {
        p {
            line-height: 18px;
            white-space: unset;
            opacity: 1;
            &:not(:last-of-type) {
                margin-bottom: 13px;
            }
        }
    }
    .list-img {
        margin-top: 16px;
        // max-height: ;
        width: 100%;
        height: 220px;
        margin-bottom: 0;
    }
}

.list-group-full {
    .list-group-item {
        margin-left: -20px;
        margin-right: -20px;
        text-align: center;
        .list-img {
            border-radius: 0;
            width: 100%;
            height: 245px;
        }
        h3,
        p {
            padding-left: 20px;
            padding-right: 20px;
        }
    }
}
