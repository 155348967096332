:root {
  --gradient-startcolor: #000000bf;
  --gradient-endcolor: #000000;
  // theme color define in html
  --btn-gradient-startcolor: #9281f4;
  --btn-gradient-endcolor: #6450dc;
  --btn-player-gradient-startcolor: #a2d246;
  --btn-player-gradient-endcolor: #009bad;
  --btnfenix-gradient-startcolor: #eb5af8;
  --btnfenix-gradient-endcolor: #ff7f00;
  --player-gradient-startcolor: rgba(69, 69, 69, 0.38);
  --player-gradient-endcolor: rgba(116, 142, 214, 0.38);
  // --btn-color: #ffffff;
  --color-white: #ffffff;
  --icon-color: #ffffff;
  --icon-like-active: #ed4a6f;
  --color-dark: #2c2c32;
  --text-color: #ffffff;
  --sub-text-color: #ffffff;
  --button-text-color: #ffffff;
  --button-border-color: #ffffff;
  --btn-rgba-color: rgba(255, 255, 255, 0.5);
  --player-card-shadow: rgba(255, 255, 255, 0.75);
  --primary-white: #ffffff !important;
  --divider-color: rgba(255, 255, 255, 0.3) !important;
  --media-bg-dark: #000000 !important;
  --media-bg-light: #ffffff !important;
  --assets-color: #05f !important;
  --theme-color: #05f !important;
  --header-height: 64px;
  --header-height-content: 40px;
  --header--notch-space-top: 40px;
  --search-height: 46px;
  --search-header-height: calc(var(--header-height) + var(--search-height));
  --font-family: 'Lato';

  --secondary-dark-gray: #2c2d2f;
  --secondary-dark-gray-rgb: 44, 45, 47;
  --secondary-gray: #909096;
  --secondary-light-gray: #e5e5e5;
  --secondary-light-gray-rgb: 229, 229, 229;
  --primary-purple: #9786ff;
  --primary-black: #000000;
  --primary-black-rgb: 0, 0, 0;
  --secondary-border: #3f3f41;

  --app-bg-color: rgba(var(--primary-black-rgb), 0.6);
  --back-btn-bg: rgba(var(--secondary-light-gray-rgb), 0.1);
  --bg-dark-blur: rgba(var(--secondary-dark-gray-rgb), 0.8);
}
