::-webkit-input-placeholder {
  color: var(--secondary-gray) !important;
}

::-moz-placeholder {
  mix-blend-mode: normal;
  color: var(--secondary-gray) !important;
}

:-ms-input-placeholder {
  mix-blend-mode: normal;
  color: var(--secondary-gray) !important;
}

:placeholder {
  mix-blend-mode: normal;
  color: var(--secondary-gray) !important;
}

.form-control {
  border-width: $input-border-width;
  @include border-radius(0px);
  border-color: $input-border-color;
  box-shadow: none;
  font-family: $font-family;
  font-size: $input-font-size;
  padding: 0.5rem 0.75rem;
  padding-left: 0;
  color: $input-text-color;
  resize: none;
  background-color: transparent;
  letter-spacing: 0.291667px;
  &:focus {
    box-shadow: none;
    outline: none;
  }
}
.search-control {
  @extend .position-relative;
  .input-search {
    padding: 0 12px 12px 30px;
    border: 0 !important;
    width: 100%;
  }
  .search-icon {
    @extend .position-absolute;
    width: auto;
  }
  &:after {
    content: '';
    background-color: #ffffff;
    mix-blend-mode: normal;
    opacity: 0.3;
    height: 1px;
    border-radius: $border-radius;
    display: block;
    @extend .position-absolute;
    top: auto;
    bottom: 0;
  }
}
.comment-input-group {
  flex: 1;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 20px;
  position: relative;
  height: 39px;
}
.comment-input {
  font-family: $font-family;

  padding: 12px 36px 12px 12px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: var(--text-color);
  background-color: transparent;
  border: 0;
  width: 100%;
  height: 39px;

  &:focus {
    box-shadow: none;
    outline: none;
  }
}
