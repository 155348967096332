button {
    @extend .c-pointer;
    &:hover,
    &:focus,
    &:active:focus {
        outline: none;
        box-shadow: none;
    }
}

.btn {
    font-family: $font-family;
    font-size: $btn-font-size;
    font-weight: $font-weight-semibold;
    padding: $btn-padding-y $btn-padding-x;
    color: var(--btn-color);
    background-color: transparent;
    border-radius: $btn-border-radius;
    text-decoration: inherit;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    border: 0;
}

.btn-gradient {
    border: 2px solid #ffffff;
}
// See all
.btn-sf {
    height: 32px;
    width: 82px;
}

.btn-primary {
    background-color: $primary;
    color: #ffffff;
}

.btn-primary-outline {
    background-color: transparent;
    border-color: $primary;
    border-width: 2px;
    color: #ffffff;
    &:hover,
    &:focus,
    &:active:focus {
        border-color: lighten($primary, 10%);
    }
}

.btn-clear {
    background-color: transparent;
    border: none;
    padding: 0;
}
.btn-play {
    padding: 0;
    background: linear-gradient(135deg, var(--btn-gradient-startcolor) 0%, var(--btn-gradient-endcolor) 100%);
    width: 24px;
    height: 24px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    svg {
        margin-left: 2px;
    }
}
.btn-arrowright {
    padding: 0;
    background: linear-gradient(135deg, var(--btnfenix-gradient-startcolor) 0%, var(--btnfenix-gradient-endcolor) 100%);
    width: 20px;
    height: 20px;
}
