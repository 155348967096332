body {
  // background: linear-gradient(0deg, var(--gradient-startcolor) 0%, var(--gradient-endcolor) 100%);
  // background-color: var(--app-bg-color-or);
  background-color: #000000;
  // backdrop-filter: blur(27.1828px);
}
#root {
  height: 100%;
  width: 100%;
  max-width: 426px;
  width: 100%;
  margin: 0 auto;
  position: relative;
}
.app-desktop-view {
  #root {
    max-width: unset;
  }
}
.app-main {
  position: relative;
  margin-right: auto;
  margin-left: auto;
  height: 100%;
  width: 100%;
  padding-top: 50px;
}
.app-landing {
  -webkit-background-size: cover !important;
  -moz-background-size: cover !important;
  -o-background-size: cover !important;
  background-size: cover !important;
  .page-content {
    padding-bottom: 128px;
  }
  .page-footer {
    padding-bottom: 94px;
  }
}
.page-header {
  height: var(--header-height);
  // @extend .position-fixed;
  position: -webkit-sticky !important;
  position: sticky !important;
  z-index: 1020;
  // top: 50px;
  padding-left: 20px;
  padding-right: 20px;
  &-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .page-heading {
    font-weight: $font-weight-medium;
    font-size: rem(12);
    line-height: 15px;
    text-align: center;
    letter-spacing: 0.923077px;
    text-transform: uppercase;
    color: var(--text-color);
    position: absolute;
    top: 0;
    margin: 0 auto;
    left: 0;
    right: 0;
    width: calc(100% - 88px);
    z-index: -2;
  }
}
.search-bar {
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 16px;
  // margin-bottom: 16px;
}
.page-content {
  padding-top: 16px;
  padding-left: 20px;
  padding-right: 20px;
  height: calc(100% - 16px);
  overflow-y: auto;
  &.has-header {
    // padding-top: calc(var(--header-height));
    height: calc(100% - (var(--header-height)));
  }
  &.has-header-search {
    // padding-top: calc(var(--header-height));
    // padding-bottom: calc(var(--header-height) + var(--search-height));
    height: calc(100% - var(--search-header-height));
  }
  &.has-footer {
    padding-bottom: 94px;
  }
}

.page-footer {
  z-index: 15;
  position: fixed;
  left: 0;
  bottom: 0;
  padding-bottom: 54px;
  width: 100%;
  &.footer-shadow {
    &:after {
      content: '';
      // background: linear-gradient(180deg, rgba(0, 0, 0, 0.0001) 67.14%, #000000 100%);
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      bottom: 0;
      z-index: 0;
      left: 0;
    }
  }
  .btn-swipeup {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    position: relative;
    z-index: 1;
    @extend .btn-clear;
  }
  .icon-arrowup {
    margin-bottom: 8px;
  }
}

.artist-logo {
  padding-top: 8px;
  padding-bottom: 13px;
  @extend .flexbox-align-c;
  img {
    object-fit: contain;
    max-height: 150px;
    margin: 0 auto;
  }
}
.about-artist {
}
.header-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}

.social-media {
  margin-bottom: 12px;
  @extend .flexbox-c;
  &-item {
    @extend .btn;
    @extend .flexbox-align-c;
    padding: 0;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    border: 2px solid #ffffff;
    &:not(:last-child) {
      margin-right: 8px;
    }
  }
}
.text-swipe {
  animation: WhiteBlack 2s linear infinite;
}
.bounce {
  animation: bounce 2s infinite;
  -webkit-animation: bounce 2s infinite;
  -moz-animation: bounce 2s infinite;
  -o-animation: bounce 2s infinite;
}
.color {
  animation: col 4s linear infinite;
}
@keyframes WhiteBlack {
  0%,
  49% {
    color: white;
    fill: white;
  }
  50%,
  100% {
    color: black;
    fill: black;
  }
}
@keyframes col {
  0%,
  49% {
    fill: white;
  }
  50%,
  100% {
    fill: black;
  }
}
@-webkit-keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-30px);
  }
  60% {
    -webkit-transform: translateY(-15px);
  }
}

@-moz-keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -moz-transform: translateY(0);
  }
  40% {
    -moz-transform: translateY(-30px);
  }
  60% {
    -moz-transform: translateY(-15px);
  }
}

@-o-keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -o-transform: translateY(0);
  }
  40% {
    -o-transform: translateY(-30px);
  }
  60% {
    -o-transform: translateY(-15px);
  }
}
@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

svg {
  vertical-align: middle;
}
.rounded {
  border-radius: 0.25rem;
}
.standalonemode {
  @media all and (display-mode: standalone) {
    display: none;
  }
}

.image-gallery-content {
  .image-gallery-slide {
    // height: 220px;
    background-color: var(--media-bg-dark);
    .image-gallery-image {
      border-radius: 15px;
      width: 100%;
      // height: 100%;
      object-fit: contain;
    }
  }
}
.image-gallery-bullets-container {
  text-align: left !important;
  .image-gallery-bullet {
    background-color: var(--text-color) !important;
    border-color: var(--text-color) !important;
    padding: 3px !important;
    &.active {
      background-color: var(--theme-color) !important;
      border-color: var(--theme-color) !important;
    }
  }
}
.product-gallery {
  .image-gallery-content {
    &.fullscreen {
      height: 100%;
      padding: 16px;
      .image-gallery-slide-wrapper {
        height: calc(100% - 180px);
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .image-gallery-slide {
        height: 100%;
      }
    }
  }
  .image-gallery-slides {
    border-radius: 15px;
  }
  .thumbnails-swipe-horizontal {
    margin-top: 13px;
  }
  .image-gallery-thumbnail {
    border: 0 !important;
    margin-left: 5px !important;
    margin-right: 5px !important;
    width: 76px;
    height: 98px;
    .image-gallery-thumbnail-inner {
      width: 100%;
      height: 100%;
      border-radius: 10px !important;
      overflow: hidden;
    }
    .image-gallery-thumbnail-image {
      object-fit: contain;
      border-radius: 10px;
    }
    &.active {
      .image-gallery-thumbnail-inner {
        opacity: 0.3;
      }
    }
  }
}
.widget-player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1099;
  padding-top: 56px;

  &:not(.slideInUp) {
    display: none !important;
  }

  &.slideOutDown {
    display: block !important;
  }
}

.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}

.animated.infinite {
  animation-iteration-count: infinite;
}

.animated.hinge {
  animation-duration: 2s;
}

.animated.flipOutX,
.animated.flipOutY,
.animated.bounceIn,
.animated.bounceOut {
  animation-duration: 0.75s;
}
.animated.flip {
  -webkit-backface-visibility: visible;
  backface-visibility: visible;
  animation-name: flip;
}
@keyframes slideInUp {
  from {
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.slideInUp {
  animation-name: slideInUp;
}

@keyframes slideOutDown {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, 100%, 0);
  }
}

.slideOutDown {
  animation-name: slideOutDown;
}

.MuiFormControl-root {
  font-family: $font-family;
  .MuiInputLabel-formControl {
    font-size: 16px;
    color: var(--secondary-gray);
    font-weight: 400;
    font-family: $font-family;
    &.Mui-focused {
      color: var(--secondary-gray);
      & + {
        .MuiInput-underline {
          &:before,
          &:after {
            border-color: var(--primary-white) !important;
          }
        }
      }
    }
  }
  .MuiInputBase-input {
    font-size: 16px;
    line-height: 17px;
    font-family: $font-family;
    color: var(--primary-white) !important;
    height: auto !important;
    padding-bottom: 10px;
  }
  .MuiInput-underline {
    &:before,
    &:after {
      border-color: var(--secondary-border) !important;
      border-width: 1px !important;
    }
  }
  .MuiInputAdornment-positionStart,
  .MuiInputAdornment-positionEnd {
    display: none !important;
  }
}

.react-checkbox {
  position: relative;
  width: 100%;
  input {
    display: none;
  }
  label {
    padding-left: 36px;
    line-height: 20px;
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: var(--primary-white) !important;
    display: block;
    cursor: pointer;
    &:before {
      content: ' ';
      background-color: transparent;
      background-repeat: no-repeat;
      background-position: center;
      position: absolute;
      top: 0;
      left: 0;
      width: 20px;
      height: 20px;
      opacity: 0.5;
      border: 1px solid var(--secondary-gray);
      border-radius: 5px;
    }
  }
  input:checked + label {
    &:before {
      opacity: 1;

      border: 0;
      background-image: url('data:image/svg+xml;utf8, <svg width="12" height="10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4 7.78 1.22 5l-.947.94L4 9.667l8-8-.94-.94L4 7.78Z" fill="rgb(255,255,255)"/></svg>'),
        linear-gradient(
          140deg,
          var(--btn-gradient-startcolor) 16.14%,
          var(--btn-gradient-endcolor) 85.45%,
          var(--btn-gradient-endcolor) 85.45%,
          var(--btn-gradient-endcolor) 85.45%
        );
    }
  }
}

.react-radio {
  position: relative;
  width: 100%;
  input {
    display: none;
  }
  label {
    padding-left: 20px;
    line-height: 20px;
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: var(--primary-white) !important;
    display: block;
    cursor: pointer;
    &:before {
      content: ' ';
      background-color: transparent;
      background-repeat: no-repeat;
      background-position: center;
      position: absolute;
      top: -10px;
      left: 0;
      width: 20px;
      height: 20px;
      opacity: 0.5;
      border: 1px solid var(--secondary-gray);
      border-radius: 50%;
    }
  }
  input:checked + label {
    &:before {
      opacity: 1;
      border: 0;
      background-image: url('data:image/svg+xml;utf8, <svg width="12" height="10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4 7.78 1.22 5l-.947.94L4 9.667l8-8-.94-.94L4 7.78Z" fill="rgb(255,255,255)"/></svg>'),
        linear-gradient(
          140deg,
          var(--btn-gradient-startcolor) 16.14%,
          var(--btn-gradient-endcolor) 85.45%,
          var(--btn-gradient-endcolor) 85.45%,
          var(--btn-gradient-endcolor) 85.45%
        );
    }
  }
}
.__react_component_tooltip {
  width: calc(100% - 90px);
  z-index: 99;
}
.react-tooltip {
  &.tooltip-artist-support {
    border-radius: 5px 5px 0px 5px !important;
    background: var(--secondary-border) !important;
    width: calc(100% - 80px) !important;
    z-index: 99;
    font-size: 12px;
    font-weight: 500;
    color: var(--primary-white, #fff) !important;
  }
}
