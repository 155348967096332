@font-face {
  font-family: 'Lato';
  src: url('../../fonts/Lato-Semibold.eot');
  src: url('../../fonts/Lato-Semibold.eot?#iefix') format('embedded-opentype'), url('../../fonts/Lato-Semibold.woff2') format('woff2'),
    url('../../fonts/Lato-Semibold.woff') format('woff'), url('../../fonts/Lato-Semibold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('../../fonts/Lato-Bold.eot');
  src: url('../../fonts/Lato-Bold.eot?#iefix') format('embedded-opentype'), url('../../fonts/Lato-Bold.woff2') format('woff2'), url('../../fonts/Lato-Bold.woff') format('woff'),
    url('../../fonts/Lato-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('../../fonts/Lato-Regular.eot');
  src: url('../../fonts/Lato-Regular.eot?#iefix') format('embedded-opentype'), url('../../fonts/Lato-Regular.woff2') format('woff2'),
    url('../../fonts/Lato-Regular.woff') format('woff'), url('../../fonts/Lato-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('../../fonts/Lato-Medium.eot');
  src: url('../../fonts/Lato-Medium.eot?#iefix') format('embedded-opentype'), url('../../fonts/Lato-Medium.woff2') format('woff2'),
    url('../../fonts/Lato-Medium.woff') format('woff'), url('../../fonts/Lato-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

$font-family: 'Lato';
