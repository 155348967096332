*,
::after,
::before {
  box-sizing: border-box;
}

*,
::after,
::before {
  box-sizing: border-box;
}
@media (min-width: 768px) {
  ::-webkit-scrollbar {
    height: 0;
    width: 3px;
  }

  ::-webkit-scrollbar-thumb {
    background: #000000;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    height: 100px;
  }

  ::-webkit-scrollbar-corner {
    background: rgba(255, 255, 255, 0.6);
  }
}
html {
  scroll-behavior: smooth;
}

html,
body {
  margin: 0;
  padding: 0;
  position: relative;
  width: 100%;
  height: 100%;
  font-family: $font-family !important;
  font-size: $font-size-base;
  text-align: left;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  font-variant-ligatures: none;
  -webkit-font-variant-ligatures: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  min-height: 100vh;
  -webkit-overflow-scrolling: touch;
  overflow: hidden;
}

body {
  background-color: $background-color;
  font-weight: $font-weight-base;
  color: $base-color;
  line-height: $line-height-base;
}
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}
img {
  max-width: 100%;
  height: auto;
}
a {
  color: inherit;
  text-decoration: none;
  &:hover,
  &:focus {
    text-decoration: none;
    color: inherit;
    outline: none;
  }
}

pre {
  font-family: $font-family;
  font-size: 1em;
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

// selector:-webkit-full-screen {
//   display: block; // displays the element only when in fullscreen
// }

// selector {
//   display: none; // hides the element when not in fullscreen mode
// }

#root {
  height: env(safe-area-inset-top);
  width: env(safe-area-inset-left);
  margin: env(safe-area-inset-right);
  // you can also use fallback values
  padding: env(safe-area-inset-bottom, 20px);
}
