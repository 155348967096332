h2 {
    font-weight: $font-weight-medium;
    font-size: rem(18);
    line-height: 22px;
    color: var(--text-color);
}
.c-pointer {
    cursor: pointer !important;
}
.overflow-hidden {
    overflow: hidden;
}

.h-100vh {
    height: 100vh;
}

.p-relative {
    position: relative;
}

.break-word {
    -ms-word-wrap: break-word !important;
    word-wrap: break-word !important;
}

.position-relative {
    position: relative;
}
.position-absolute {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}
.position-fixed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
}

.line-divider {
    margin-top: 24px;
    margin-bottom: 24px;
    height: 1px;
    width: 100%;
    background-color: var(--divider-color);
    opacity: 0.2;
}
.mb-0 {
    margin-bottom: 0;
}
.mb-15 {
    margin-bottom: 15px;
}
.opacity-7 {
    opacity: 0.7;
    mix-blend-mode: normal;
}
.img-cover {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.text-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
